define('ember-cli-is-component/services/is-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      getOwner = Ember.getOwner;
  exports.default = Service.extend({
    test: function test(name) {
      name = (name || '').trim();

      if (!name) {
        return false;
      }

      var owner = getOwner(this);
      var lookup = owner.lookup('component-lookup:main');

      if (!lookup.componentFor) {
        return !!lookup.lookupFactory(name);
      }

      return !!(lookup.componentFor(name, owner) || lookup.layoutFor(name, owner));
    }
  });
});