define('websocket/services/websocket', ['exports', 'frontend/config/environment', 'ember-inflector'], function (exports, _environment, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var deletedStates = ['root.deleted.inFlight', 'root.deleted.saved'];

  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),

    listeners: [],

    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }),

    init: function init() {
      this.createFayeClient();
    },
    registerListener: function registerListener(listener) {
      this.get('listeners').pushObject(listener);

      return this;
    },
    unregisterListener: function unregisterListener(listener) {
      this.get('listeners').removeObject(listener);

      return this;
    },
    createFayeClient: function createFayeClient() {
      var options = { timeout: 120, retry: 5 };
      this.fayeClient = new Faye.Client(_environment.default.websocketUrl, options);

      return this;
    },
    subscribeToChannel: function subscribeToChannel(channel) {
      var _this = this;

      this.log('Subscribing to channel ' + channel);

      this.subscribe(channel, 'create', function (message) {
        var type = (0, _emberInflector.singularize)(message.payload.data.type),
            id = message.payload.data.id;

        _this.log('Creating ' + type + ':' + id + ' from websocket push');

        _this.handleCreateOrUpdate(type, id, message.payload);
      });

      this.subscribe(channel, 'update', function (message) {
        var type = (0, _emberInflector.singularize)(message.payload.data.type),
            id = message.payload.data.id;

        _this.log('Updating ' + type + ':' + id + ' from websocket push');

        _this.handleCreateOrUpdate(type, id, message.payload);
      });

      this.subscribe(channel, 'delete', function (message) {
        return _this.onChannelDelete(message);
      });
    },
    onChannelDelete: function onChannelDelete(message) {
      var type = message.type,
          id = message.id,
          existingRecord = this.get('store').peekRecord(type, id);

      // Unloading a record that's inFlight causes an error. There is no need to
      // wait here though, as if it's currently deleting and is inFlight it will
      // be removed from the store after the request is completed anyway
      this.log('Deleting ' + type + ':' + id + ' from websocket push');

      var isUnloadable = existingRecord && !deletedStates.includes(existingRecord.get('currentState.stateName'));

      if (isUnloadable) {
        this.get('listeners').invoke('beforeUnloadRecord', existingRecord);
        existingRecord.unloadRecord();
        this.get('router').send('recordDeletedViaPush', existingRecord);
      }
    },
    handleCreateOrUpdate: function handleCreateOrUpdate(type, id, payload) {
      var store = this.get('store');

      this.queuePush(function () {
        var existingRecord = store.peekRecord(type, id);

        if (!existingRecord || !deletedStates.includes(existingRecord.get('currentState.stateName'))) {
          store.pushPayload(type, payload);
          // Look up the record so that wasPushed can be called on create
          existingRecord = existingRecord || store.peekRecord(type, id);

          if (existingRecord && typeof existingRecord.wasPushed === 'function') {
            existingRecord.wasPushed();
          }
        }
      });
    },


    // If there are currently _any_ records that are in the process of
    // creating, pause the push until they are created. This is because it's
    // impossible to know if the pushed record is the record that's currently
    // being created - in fact, it's quite likely this is the case.
    //
    // Without pausing to allow inFlight requests to complete, the store
    // will frequently end up with duplicate records
    //
    // Use a FIFO queue to ensure that the store pushes are processed in the
    // order they are received

    queuePush: function queuePush(callback) {
      var _this2 = this;

      this.pushQueue = this.pushQueue || [];
      this.pushQueue.push(callback);

      if (this._waiterId) {
        return;
      }

      var waiter = function waiter() {
        if (!_this2.get('isDestroyed') && Ember.$.active === 0) {
          Ember.run.next(function () {
            _this2.pushQueue.forEach(function (callback) {
              return callback();
            });
            _this2.pushQueue = [];
            delete _this2._waiterId;
          });
        } else {
          _this2._waiterId = Ember.run.later(_this2, waiter, 100);
        }
      };

      waiter();
    },
    unsubscribeFromChannel: function unsubscribeFromChannel(channel) {
      this.log('Unsubscribing from channel ' + channel);
      this.fayeClient.unsubscribe(channel);
    },
    subscribe: function subscribe(channel, event, callback) {
      this.fayeClient.subscribe(channel, function (message) {
        if (message.event === event) {
          // because websocket events are triggered externally, they must be
          // wrapped inside an explicit runloop
          Ember.run(function () {
            return callback(message);
          });
        }
      });
    },
    log: function log(message) {
      Ember.Logger.debug("[Websocket Client] " + message);
    }
  });
});